import { AuthorizationTypes } from 'actions/authorizationAction'
import {
  CounterDownTypes,
  DECREASE_COUNTER_DOWN,
  SET_COUNTER_DOWN,
  SET_COUNTER_DOWN_ID,
} from 'actions/utility/counterDown/counterDownAction'
import { defaultPromiseReducer } from 'reducers/defaultPromiseReducer'
import { LOGOUT_ACTION } from 'actions/authorization/logoutAction'

export interface CounterDownState {
  timer: number
  timerId: number
}

const initialState: CounterDownState = {
  timer: 0,
  timerId: 0,
}

export const counterDownReducer = (
  state = initialState,
  action: CounterDownTypes | AuthorizationTypes
): CounterDownState => {
  switch (action.type) {
    case SET_COUNTER_DOWN:
      return {
        ...state,
        timer: action.timer,
      }

    case DECREASE_COUNTER_DOWN:
      return {
        ...state,
        timer: state.timer - 1,
      }

    case SET_COUNTER_DOWN_ID:
      return {
        ...state,
        timerId: action.id,
      }

    case LOGOUT_ACTION:
      return defaultPromiseReducer(state, action, undefined, () => ({
        ...initialState,
      }))

    default:
      return state
  }
}
