import React, { FC, SVGProps } from 'react'

export const PasswordEyeSvg: FC<
  SVGProps<SVGSVGElement> & { crossedEye: boolean }
> = ({ crossedEye, ...rest }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {crossedEye ? (
        <>
          <path
            d="M21.7478 9.01022C21.4102 8.57091 20.775 8.51453 20.355 8.87579C15.8512 12.7493 12.1996 16.7623 9.91478 19.3708C9.44792 19.9038 9.67825 20.736 10.3759 20.86C15.0469 21.6904 19.1734 18.7009 21.9795 15.2259C23.3493 13.5294 23.3226 11.1222 22.0215 9.37247C21.9302 9.24969 21.839 9.12895 21.7478 9.01022Z"
            fill="#2E2A29"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.93481 9.31912C0.61729 11.0803 0.710974 13.5134 2.09879 15.2198C2.83497 16.1249 3.56886 16.9084 4.29804 17.5806L2.43934 19.4393C1.85355 20.0251 1.85355 20.9749 2.43934 21.5607C3.02513 22.1464 3.97487 22.1464 4.56066 21.5607L21.5607 4.56066C22.1464 3.97487 22.1464 3.02513 21.5607 2.43934C20.9749 1.85355 20.0251 1.85355 19.4393 2.43934L17.2324 4.64632C11.0607 0.639792 5.44343 4.62892 1.93481 9.31912ZM7.99805 12.0054C7.99805 9.79755 9.78677 8.00781 11.9933 8.00781C12.5472 8.00781 13.0747 8.12058 13.5542 8.32443L8.31358 13.5651C8.11042 13.0858 7.99805 12.5587 7.99805 12.0054Z"
            fill="#2E2A29"
          />
        </>
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.93579 9.31912C6.33643 3.4365 14.0542 -1.34327 22.0225 9.37247C23.3236 11.1222 23.3503 13.5294 21.9805 15.2259C17.593 20.6593 9.97737 24.9056 2.09976 15.2198C0.71195 13.5134 0.618267 11.0803 1.93579 9.31912ZM11.9933 16.0029C14.1998 16.0029 15.9885 14.2131 15.9885 12.0054C15.9885 9.79757 14.1998 8.00781 11.9933 8.00781C9.78678 8.00781 7.99805 9.79757 7.99805 12.0054C7.99805 14.2131 9.78678 16.0029 11.9933 16.0029Z"
          fill="#2E2A29"
        />
      )}
    </svg>
  )
}
