import React, { SVGProps } from 'react'

export const MailSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.41744 3.72548L7.06033 8.00354C8.62843 9.44845 11.0344 9.47627 12.6356 8.06798L17.5783 3.72065C16.967 2.97541 16.039 2.5 14.9998 2.5H4.99984C3.95848 2.5 3.02871 2.9775 2.41744 3.72548Z"
      fill="#F2F5F7"
    />
    <path
      d="M3.05209 6.64969C2.51521 6.17143 1.6665 6.55313 1.6665 7.27214V14.1667C1.6665 16.0076 3.15886 17.5 4.99984 17.5H14.9998C16.8408 17.5 18.3332 16.0076 18.3332 14.1667V7.20926C18.3332 6.50048 17.5056 6.11491 16.9643 6.57245C15.7794 7.57401 14.1871 8.92293 13.7363 9.31946C11.4947 11.291 8.12631 11.2521 5.93093 9.22923C5.52559 8.85573 4.13613 7.61538 3.05209 6.64969Z"
      fill="#F2F5F7"
    />
  </svg>
)
