import React, { FC, SVGProps } from 'react'

export const ArrowRightSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.69087 27.8954C8.0124 27.0237 8.169 25.7671 9.04064 25.0887L19.7428 16.7583C20.08 16.4959 20.0867 15.9884 19.7565 15.7171L8.99946 6.87888C8.14601 6.17766 8.02259 4.91736 8.72381 4.06391C9.42502 3.21046 10.6853 3.08705 11.5388 3.78826L22.2959 12.6265C24.6069 14.5253 24.5601 18.0776 22.1998 19.9148L11.4976 28.2451C10.6259 28.9236 9.36934 28.767 8.69087 27.8954Z"
        fill="#F2F5F7"
      />
    </svg>
  )
}
