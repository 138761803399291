import { fetchApi, fetchQueryApi, postApi } from 'api/fetchApi'
import { paths } from 'api/generated/liveness'

type Request = paths['/liveness/session/create']
type Result = Request['post']['responses']['200']['schema']

type RequestStatus = paths['/liveness/session/status']
type RequestParametersStatus = RequestStatus['get']['parameters']['query']
type ResultStatus = RequestStatus['get']['responses']['200']['schema']

export const fetchSessionIdLivenessApi = () =>
  postApi<Result, {}>('/liveness/session/create')

export const fetchStatusVerificationLivenessApi = (sessionId: string) =>
  fetchQueryApi<ResultStatus, RequestParametersStatus>(
    '/liveness/session/status',
    {
      sessionId,
    }
  )
