export enum PhoneVerificationMethod {
  MobileId = 'mobile_id',
  Sms = 'sms',
  Call = 'call',
  VoiceCode = 'voice_code',
}

export enum PhoneVerificationAction {
  /** Выполнить повторную отправку кода */
  ResendCode = 'resend_code',
  /** Сменить метод верификации */
  ChangeMethod = 'change_method',
}
