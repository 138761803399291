import { styled } from '@linaria/react'
import { VoidHandler, WithChildren } from 'common/types'
import { Loader } from 'components/designSystem/Loader/Loader'
import { Typography } from 'components/designSystem/Typography/Typography'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { buttonResetCss } from 'components/designSystem/styles/buttonResetCss'
import { CloseSvg } from 'components/designSystem/svgr/CloseSvg'
import React, { FC, ReactNode } from 'react'

export const OnboardingLayout: FC<
  {
    title?: ReactNode
    subtitle?: ReactNode
    onCloseButtonClick?: VoidHandler
    loading?: boolean
  } & WithChildren
> = ({ title, subtitle, children, onCloseButtonClick, loading }) => {
  return (
    <Wrapper>
      {!loading && (
        <TitleBlock>
          <Inner>
            {title && (
              <Title fontSize={32} fontWeight={700}>
                {title}
              </Title>
            )}
            {subtitle ? <Subtitle fontSize={20}>{subtitle}</Subtitle> : null}
          </Inner>
        </TitleBlock>
      )}

      {onCloseButtonClick && (
        <CloseButton onClick={onCloseButtonClick}>
          <CloseSvg />
        </CloseButton>
      )}

      {loading ? (
        <CenteredLoader />
      ) : (
        <OnboardingStepLayoutContent>{children}</OnboardingStepLayoutContent>
      )}
    </Wrapper>
  )
}

const CenteredLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const CloseButton = styled.button`
  ${buttonResetCss};
  position: absolute;
  top: 12px;
  right: var(--spacing-16px, 16px);

  @media (min-width: ${breakpoints.mobile}px) {
    top: 40px;
    right: 40px;
  }
`
export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`
const Title = styled(Typography)``
const Subtitle = styled(Typography)`
  margin-top: var(--spacing-16px, 16px);
`
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: var(--spacing-16px, 16px);
  padding-right: var(--spacing-16px, 16px);
  padding-top: 76px;

  @media (min-width: ${breakpoints.mobile}px) {
    padding-left: var(--spacing-80px, 80px);
    padding-right: var(--spacing-80px, 80px);
    padding-top: 96px;
  }
`
const OnboardingStepLayoutContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: var(--spacing-24px, 24px);
`
const TitleBlock = styled.div`
  padding-left: var(--spacing-6px, 6px);
  padding-right: var(--spacing-6px, 6px);
`
