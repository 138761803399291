import { noop } from 'common/constants'
import { Wave } from 'components/designSystem/Wave/Wave'
import { WaveImperativeHandlers } from 'components/designSystem/Wave/WaveInner'
import { usePrevious } from 'hooks/usePrevious'
import React, {
  FC,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

/** Этот компонент качает волну */
export const WaveController: FC<{
  step: number
  mobile: boolean
  boxesElements: ReactNode
}> = ({ step, mobile, boxesElements }) => {
  const waveHandlersRef = useRef<WaveImperativeHandlers>({
    pumpWaveForward: noop,
    pumpWaveBackward: noop,
  })

  const [pumping, setPumping] = useState(false)
  const [pumps, setPumps] = useState(0)
  const prevStep = usePrevious(step)

  useEffect(() => {
    if (step === prevStep) {
      return
    }
    setPumps((pumps) => pumps + step - prevStep)
  }, [step, prevStep, setPumps])

  useEffect(() => {
    if (pumps === 0 || pumping) {
      return
    }
    if (pumps > 0) {
      waveHandlersRef.current.pumpWaveForward()
    } else {
      waveHandlersRef.current.pumpWaveBackward()
    }
    setPumps((pumps) => (pumps > 0 ? pumps - 1 : pumps + 1))
    setPumping(true)
  }, [pumping, pumps])

  const handlePumpFinished = useCallback(() => {
    setPumping(false)
  }, [])

  return (
    <MemoizedWave
      step={step}
      ref={waveHandlersRef}
      onPumpFinished={handlePumpFinished}
      mobile={mobile}
      boxesElements={boxesElements}
    />
  )
}

const MemoizedWave = memo(Wave)
