import {
  fetchSessionIdLivenessApi,
  fetchStatusVerificationLivenessApi,
} from 'api/settings/photoVerificationLivenessApi'

export const FETCH_SESSION_ID_LIVENESS = 'FETCH_SESSION_ID_LIVENESS' as const

export const fetchSessionIdLivenessAction = () => ({
  type: FETCH_SESSION_ID_LIVENESS,
  promise: () => fetchSessionIdLivenessApi(),
})

export const FETCH_STATUS_VERIFICATION_LIVENESS = 'FETCH_STATUS_VERIFICATION_LIVENESS' as const

export const fetchStatusVerificationLivenessAction = (sessionId: string) => ({
  type: FETCH_STATUS_VERIFICATION_LIVENESS,
  promise: () => fetchStatusVerificationLivenessApi(sessionId),
})

export const RESET_LIVENESS_STATUS = 'RESET_LIVENESS_STATUS' as const

export const resetLivenessStatusAction = () => ({
  type: RESET_LIVENESS_STATUS,
})
